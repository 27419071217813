/**
 * This popup needs to work on as many browsers as possible and with all the
 * frameworks we've got, so this JS gets very vanilla at times.
 */

if (!cookieIsSet()) {
    const espanol = {
        html: 'Este sitio web utiliza cookies para optimizar su experiencia, analizar el tráfico y personalizar el contenido. Al hacer clic en Aceptar y / o usar este sitio, usted acepta nuestro uso de cookies, <a style="text-decoration:underline;color:white;" href="/Privacy">Política de privacidad</a> y <a style="text-decoration:underline;color:white;" href="/Terms">Condiciones de uso</a>.',
        buttonText: 'Aceptar',
    };
    const english = {
        html: 'This website uses cookies to optimize your experience, analyze traffic and personalize content. By clicking Accept and/or using this site, you consent to our use of cookies, <a style="text-decoration:underline;color:white;" href="/Privacy">Privacy Policy</a> and <a style="text-decoration:underline;color:white;" href="/Terms">Terms of Use</a>.',
        buttonText: 'Accept',
    };
    const html = require('./popup.html');
    const lang = /fondodemiel/.test(location.hostname)
        ? espanol
        : english;

    // This box will contain our popup and hold open some space for it at the
    // bottom of the page (with the help of `popupMock`)
    const box = document.createElement('div');
    document.body.classList.add("has-cookie-banner");
    document.body.appendChild(box);

    const removeBox = function () {
        clearInterval(kingMaker);
        transitionOut(box, popup, function () {
            box.parentNode.removeChild(box);
            document.body.classList.remove("has-cookie-banner");
        });
    };

    // Build the popup and put it in the box
    const popup = buildPopup(html, lang, removeBox);
    box.appendChild(popup);

    // This element will make the page grow by the size of the popup and so
    // ensure the popup doesn't hide anything that exists in the normal flow.
    const popupMock = popup.cloneNode(true);
    popupMock.style.position = '';
    popupMock.style.visibility = 'hidden';
    box.appendChild(popupMock);

    // Force our popup to the very tippy top visually by making it the last
    // element on the page. The popup uses the highest z-index, but if other
    // elements use the same one, the last one on the page is the winner.
    // This interval ensures the GDPR popup is always the last thing on the
    // page. Always.
    //
    // In our case at Honeyfund we want to make sure the Intercomm button
    // doesn't cover the Accept button.
    const kingMaker = setInterval(function () {
        if (box.parentNode && box.parentNode.childNodes[box.parentNode.childNodes.length - 1] !== box) {
            box.parentNode.appendChild(box);
        }
    }, 250);
}

function cookieIsSet() {
    const pairs = document.cookie.split(';');
    const cookies = {};
    for (let i in pairs) {
        const pair = pairs[i].trim().split('=', 2);
        if (pair[0] === 'gdpr_accepted' && pair[1] === '1') {
            return true;
        }
    }
    return false;
}

function setCookie() {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 30); // 30 years. It's a nice round number.
    document.cookie = 'gdpr_accepted=1; Expires=' + expires.toGMTString() + '; Path=/; Domain=' + location.hostname + ';';
}

function transitionOut(box, popup, onDone) {
    if (!box.getBoundingClientRect) { // some browsers don't have it
        onDone();
        return;
    }

    // if we can give a friendly animation, do so
    const boundingRect = box.getBoundingClientRect();

    // empty the box
    while (box.firstChild) {
        box.removeChild(box.firstChild);
    }

    // but keep the popup in there
    box.appendChild(popup);

    // the box can control its own size in this kind of browser
    box.style.minHeight = boundingRect.height + 'px';

    const timeToRemoval = 100;
    const loopTime = 10;
    const increment = boundingRect.height / (timeToRemoval / loopTime);

    let y = 0;

    const loop = setInterval(function () {
        y += increment;

        // Transition the popup down
        popup.style.bottom = '-' + y + 'px';

        // Just in case we are scrolled to the bottom of the screen, shrink the
        // containing box as well, so the page doesn't suddenly collapse on the
        // space it was taking up
        box.style.minHeight = (boundingRect.height - y) + 'px';
    }, loopTime);

    setTimeout(function () {
        clearInterval(loop);
        onDone();
    }, timeToRemoval);
}

function buildPopup(html, lang, onDone) {
    const sandbox = document.createElement('div');
    sandbox.innerHTML = html;
    const popup = sandbox.firstChild;

    const onClick = function () {
        setCookie();
        onDone();
    };

    const text = popup.getElementsByClassName('gdpr-text')[0];
    text.innerHTML = lang.html;

    const button = popup.getElementsByClassName('gdpr-accept-button')[0];
    button.innerText = lang.buttonText;
    if (button.addEventListener) {
        button.addEventListener('click', onClick);
    } else if (button.attachEvent) {
        button.attachEvent('click', onClick);
    } // else: This never happens.

    return popup;
}
